
/**
 * 判断浏览器是否微信
 * @returns boolean
 */
function isWeixin() {
  return /micromessenger|weixin|wechat/.test(navigator.userAgent.toLocaleLowerCase())
}

/**
 * 判断手机品牌
 * @returns 品牌名称
 */
function getPhoneBrand() {
  let ua = navigator.userAgent.toLocaleLowerCase()
  console.info('ua', ua)
  if (/vivo/.test(ua)) {
    return 'vivo'
  } else if (/huawei|honor/.test(ua)) {
    return 'huawei'
  } else if (/hm|redmi|mi\s|mix\s|mi-|xiaomi/.test(ua)) {
    return 'mi'
  } else if (/oppo|pacm00/.test(ua)) {
    return 'oppo'
  }
  return 'other'
}

export { isWeixin, getPhoneBrand }
